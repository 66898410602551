export default [
  {
    name: '人保',
    label: '人保',
    value: 'PICC',
    status: '-1'
  },
  {
    name: '太保',
    label: '太保',
    value: 'CPIC',
    status: '-1'
  },
  {
    name: '平安',
    label: '中国平安',
    value: 'PAIC',
    status: '-1'
  },
  {
    name: '中华联合',
    label: '中华联合',
    value: 'CICP',
    status: '-1'
  },
  {
    name: '阳光',
    label: '阳光保险',
    value: 'YGBX',
    status: '-1'
  },
  {
    name: '太平',
    label: '中国太平',
    value: 'TPIC',
    status: '-1'
  },
  {
    name: '国寿财',
    label: '国寿财',
    value: 'GPIC',
    status: '-1'
  },
  {
    name: '大地财险',
    label: '大地财险',
    value: 'CCIC',
    status: '-1'
  },
  {
    name: '中银',
    label: '中银财险',
    value: 'BOCI',
    status: '-1'
  }
]
