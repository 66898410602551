import { cloneDeep } from "lodash";
import { Toast, Dialog } from "vant";
import { request } from "@/api/service";
import store from "@/store";
import ImagesQuicklyCompress from "images-quickly-compress";
import companyCompare from "./company";
export default {
  data() {
    return {
      pageForm: {},
      pageLayout: {},
    };
  },
  methods: {
    initPageData(layoutData) {
      //初始化表单和布局
      for (const keyCode in layoutData) {
        const formItem = layoutData[keyCode];
        this.$set(this.pageForm, formItem.fmCode, formItem.form || {});
        this.$set(this.pageLayout, formItem.fmCode, {});
        const formItems = formItem.formItems;
        formItems &&
          formItems.forEach((item) => {
            this.$set(
              this.pageForm[formItem.fmCode],
              item.prop,
              item.config && item.config.val
            );
            this.$set(this.pageLayout[formItem.fmCode], item.prop, item);
          });
      }
    },
    transformReqData(form) {
      const cloneObj = cloneDeep(form);
      const reqParam = {
        basis: cloneObj.basis,
        vehicle: { ...cloneObj.vehicle, mileage: cloneObj.basis.mileage, sendhandCarFlag: cloneObj.basis.sendhandCarFlag },
        owner: cloneObj.owner,
        holder: cloneObj.holder,
        insured: cloneObj.insured,
      };
      if (cloneObj.compulsory.checked) {
        reqParam.compulsory = cloneObj.compulsory;
        reqParam.compulsory.immediateFlag = 0;
      }
      if (cloneObj.commercial.checked) {
        reqParam.commercial = cloneObj.commercial;
        reqParam.commercial.immediateFlag = 0;
      }
      //=========数据特殊处理============
      if (((this.$route.query && this.$route.query.id) || reqParam.basis.subBusinessNo) && !['3', '4', '5', '6'].includes(this.quote&&this.quote.resultCode)) {
        reqParam.basis.insBusinessNo = null
        reqParam.basis.subBusinessNo = null
        reqParam.vehicle.insVehicleModelId = null
      }
      reqParam.basis.plateNo = reqParam.basis.plateNo === '新车未上牌' ? null : reqParam.basis.plateNo
      // 经销商端向后台传缓存里的经销商id和name
      const { info } = this.$store.getters
      if (info.busiType === '2') {
        reqParam.basis.dealerId = info.orgId
        reqParam.basis.dealerName = info.orgName
      }
      // 处理机构的orgId的传参
      if (info.busiType === '1') {
        let len = reqParam.basis.orgIdVal.length - 1
        if (len > 0 || len === 0) {
          reqParam.basis.orgId = reqParam.basis.orgIdVal[len]
        }
      }
      //基本信息
      reqParam.basis.insuredOrgName = "";
      if (reqParam.basis.insuredOrgCode) {
        const nameMap = {};
        companyCompare.map(item => {
          nameMap[item.value] = item.label
        })
        reqParam.basis.insuredOrgName = nameMap[reqParam.basis.insuredOrgCode];
      }
      //车辆信息
      if (reqParam.vehicle.plateFlag === true) {
        reqParam.vehicle.newCarFlag = "1";
      }
      //车主信息
      if (reqParam.owner.areaCodes) {
        reqParam.owner.provinceCode = reqParam.owner.areaCodes[0];
        reqParam.owner.cityCode = reqParam.owner.areaCodes[1];
        reqParam.owner.countyCode = reqParam.owner.areaCodes[2];
      }
      //投保人
      if (reqParam.holder.sameOwnerFlag === true) {
        reqParam.holder.sameOwnerFlag = "1";
      }else{
        reqParam.holder.sameOwnerFlag = "0";
      }
      if (reqParam.holder.areaCodes) {
        reqParam.holder.provinceCode = reqParam.owner.areaCodes[0];
        reqParam.holder.cityCode = reqParam.owner.areaCodes[1];
        reqParam.holder.countyCode = reqParam.owner.areaCodes[2];
      }
      //投保人联系人
      if (reqParam.holder.custType === "1") {
        reqParam.holder.operator = {
          name: reqParam.holder.operator_name,
          certificateType: reqParam.holder.operator_certificateType,
          certificateNo: reqParam.holder.operator_certificateNo,
          mobile: reqParam.holder.operator_mobile,
        };
      } else {
        reqParam.holder.operator = null;
      }
      //被保人
      if (reqParam.insured.sameOwnerFlag === true) {
        reqParam.insured.sameOwnerFlag = "1";
      }else{
        reqParam.insured.sameOwnerFlag = "0";
      }
      if (reqParam.insured.sameHolderFlag === true) {
        reqParam.insured.sameHolderFlag = "1";
      }else{
        reqParam.insured.sameHolderFlag = "0";
      }
      if (reqParam.insured.areaCodes) {
        reqParam.insured.provinceCode = reqParam.owner.areaCodes[0];
        reqParam.insured.cityCode = reqParam.owner.areaCodes[1];
        reqParam.insured.countyCode = reqParam.owner.areaCodes[2];
      }
      //被保人联系人
      if (reqParam.insured.custType === "1") {
        if (reqParam.holder.custType === "1") {
          reqParam.insured.operator = {
            name: reqParam.holder.operator_name,
            certificateType: reqParam.holder.operator_certificateType,
            certificateNo: reqParam.holder.operator_certificateNo,
            mobile: reqParam.holder.operator_mobile,
          };
        } else {
          reqParam.insured.operator = {
            name: reqParam.insured.operator_name,
            certificateType: reqParam.insured.operator_certificateType,
            certificateNo: reqParam.insured.operator_certificateNo,
            mobile: reqParam.insured.operator_mobile,
          };
        }
      } else {
        reqParam.insured.operator = null;
      }
      //交强险
      if (reqParam.compulsory && reqParam.compulsory.checked) {
        const vehicleTax = {
          taxType: reqParam.compulsory.taxType,
          taxAgency: reqParam.compulsory.taxAgency,
          taxCertificateNo: reqParam.compulsory.taxCertificateNo,
          taxDocumentDate: reqParam.compulsory.taxDocumentDate,
          taxAbateReason: reqParam.compulsory.taxAbateReason,
          taxAbateType: reqParam.compulsory.taxAbateType,
          taxAbateProportion: reqParam.compulsory.taxAbateProportion,
          taxAbateAmount: reqParam.compulsory.taxAbateAmount,
        };
        reqParam.compulsory.vehicleTax = vehicleTax;
      }
      //充电桩处理
      if(reqParam.vehicle.newEnergyFlag==='1'&&reqParam.commercial&&reqParam.commercial.checked){
        const filterRisk=reqParam.commercial.riskList.filter((item)=>{return item.riskCode==='COMM1M02'||item.riskCode==='COMM1M03'})
        if(filterRisk.length>0){
          reqParam.chargePileList=filterRisk[0]&&filterRisk[0].chargePileList
          reqParam.chargePileList.forEach(item=>{
            item.szAmount=filterRisk[0]&&filterRisk[0].amount
            item.zrAmount=filterRisk[1]&&filterRisk[1].amount
          })
          filterRisk&&filterRisk.forEach(item=>{
            item.quantity=filterRisk.length
          })
        }else{
          reqParam.chargePileList=null
        }
      }else{
        reqParam.chargePileList=null
      }
      //数据处理
      //验车传参处理
      //   验车类型
      if (!(reqParam.vehicle.vehicleInspectionFlag === '1' && reqParam.basis.insuredOrgCode === 'PAIC')) {
        reqParam.vehicle.paicType = null
      }
      // 验车时间
      if (!(reqParam.vehicle.vehicleInspectionFlag === '1' && reqParam.basis.insuredOrgCode === 'PAIC' || reqParam.vehicle.vehicleInspectionFlag === '4' && reqParam.basis.insuredOrgCode === 'PICC')) {
        reqParam.vehicle.inspectionTime = null
      }
      // 验车时间段
      if (!(reqParam.vehicle.vehicleInspectionFlag === '1' && reqParam.basis.insuredOrgCode === 'PAIC')) {
        reqParam.vehicle.inspectionTimePeriod = null
      }
      // 验车地点
      if (!(reqParam.vehicle.vehicleInspectionFlag === '1' && reqParam.basis.insuredOrgCode === 'PAIC')) {
        reqParam.vehicle.address = null
      }
      // 验车人
      if (!(reqParam.vehicle.vehicleInspectionFlag === '1' && reqParam.basis.insuredOrgCode === 'GPIC' || reqParam.vehicle.vehicleInspectionFlag === '4' && reqParam.basis.insuredOrgCode === 'GPIC')) {
        reqParam.vehicle.inspector = null
      }
      return reqParam;
    },
    vehicleQuoteIn() {
      //数据转换
      const reqParam = this.transformReqData(this.pageForm);
      // this.pageData.submitLoading = true;
      let setInter = setInterval(() => {
        Toast.loading({
          message: "报价中...",
          overlay: true,
          forbidClick: true
        }, 500)
      })
      this.$store.dispatch("car/setQuote", reqParam)
      request({
        url: "/afis-api-manage/invoke/core/auto/vehicleQuoteIn",
        method: "post",
        data: reqParam,
        showError: false
      })
        .then(async (resParam) => {
          clearInterval(setInter)
          Toast.clear()
          this.pageForm.resultCode=resParam.resultCode
          // this.pageData.submitLoading = false;
          if (resParam.resultCode === "6") {
            //车型二次确认
            this.$refs.vehicleSelectRef.open(resParam);
            this.$store.dispatch("car/setQuote", this.pageForm);
            return false;
          } else if (resParam.resultCode === "5") {
            //验证码
            this.pageForm.basis.insBusinessNo = resParam.basis.insBusinessNo; //验证码需要
            this.pageForm.vehicle.jgCheckNo = resParam.vehicle.jgCheckNo; //验证码 中银需要
            this.$store.dispatch("car/setQuote", this.pageForm);
            this.$refs.checkCodeRef.open(resParam);
            return false;
          }
          this.pageForm.vehicle.jgCheckCode = null; //交管所验证码
          this.pageForm.vehicle.jgCheckNo = null; //验证码 中银需要
          this.pageForm.basis.insBusinessNo = null; //验证码需要
          this.pageForm.vehicle.insVehicleModelId = null; //车型二次确认
          this.$store.dispatch("car/setQuote", this.pageForm);
          const resData = {};
          //充电桩特殊处理
          if(reqParam.chargePileList){
            resParam.chargePileList=reqParam.chargePileList
          }else{
            resParam.chargePileList=null
          }
          if (reqParam.holder.sameOwnerFlag === 'false'||reqParam.holder.sameOwnerFlag === '0') {
                reqParam.holder.sameOwnerFlag = false
            }else if (reqParam.holder.sameOwnerFlag === 'true'||reqParam.holder.sameOwnerFlag === '1') {
                reqParam.holder.sameOwnerFlag = true
            }
            if (reqParam.insured.sameHolderFlag === 'false'||reqParam.insured.sameHolderFlag === '0') {
                reqParam.insured.sameHolderFlag = false
            }else if (reqParam.insured.sameHolderFlag === 'true'||reqParam.insured.sameHolderFlag === '1') {
                reqParam.insured.sameHolderFlag = true
            }
            if (reqParam.insured.sameOwnerFlag === 'false'||reqParam.insured.sameOwnerFlag === '0') {
                reqParam.insured.sameOwnerFlag = false
            }else if (reqParam.insured.sameOwnerFlag === 'true'||reqParam.insured.sameOwnerFlag === '1') {
                reqParam.insured.sameOwnerFlag = true
            }
            if (reqParam.holder.sameOwnerFlag === 'false'||reqParam.holder.sameOwnerFlag === '0') {
                reqParam.holder.sameOwnerFlag = false
            }else if (reqParam.holder.sameOwnerFlag === 'true'||reqParam.holder.sameOwnerFlag === '1') {
                reqParam.holder.sameOwnerFlag = true
            }
            if (reqParam.insured.sameHolderFlag === 'false'||reqParam.insured.sameHolderFlag === '0') {
                reqParam.insured.sameHolderFlag = false
            }else if (reqParam.insured.sameHolderFlag === 'true'||reqParam.insured.sameHolderFlag === '1') {
                reqParam.insured.sameHolderFlag = true
            }
            if (reqParam.insured.sameOwnerFlag === 'false'||reqParam.insured.sameOwnerFlag === '0') {
                reqParam.insured.sameOwnerFlag = false
            }else if (reqParam.insured.sameOwnerFlag === 'true'||reqParam.insured.sameOwnerFlag === '1') {
                reqParam.insured.sameOwnerFlag = true
            }
            this.formData = resParam
          resData[reqParam.basis.insuredOrgCode] = resParam;
          await this.$store.dispatch("car/setQuoteRes", resData); //保存报价结果
          this.$router.push({ path: "/miniprogram/quote/result" });
        })
        .catch((error) => {
          clearInterval(setInter)
          Toast.clear()
          // this.pageData.submitLoading = false;
          Dialog.alert({
            message: error.message || "报价失败",
          }).then(() => {});
          this.pageForm.vehicle.jgCheckCode = null; //交管所验证码
          this.pageForm.vehicle.jgCheckNo = null; //验证码 中银需要
          this.pageForm.basis.insBusinessNo = null; //验证码需要 平安需要
          this.pageForm.vehicle.insVehicleModelId = null; //车型二次确认
          this.$store.dispatch("car/setQuote", this.pageForm);
        });
    },
    vehicleQuote() {
      //数据转换
      const reqParam = this.transformReqData(this.pageForm);
      let setInter = setInterval(() => {
        Toast.loading({
          message: "报价中...",
          overlay: true,
          forbidClick: true
        }, 500)
      })
      this.$store.dispatch("car/setQuote", reqParam)
      request({
        url: "/afis-api-manage/invoke/core/auto/vehicleQuoteIn",
        method: "post",
        data: reqParam,
      })
        .then(async (resParam) => {
          clearInterval(setInter)
          Toast.clear()
          this.pageForm.resultCode=resParam.resultCode
          if (resParam.resultCode === "6") {
            //车型二次确认
            this.$refs.vehicleSelectRef.open(resParam);
            return false;
          } else if (resParam.resultCode === "5") {
            //验证码
            this.pageForm.basis.insBusinessNo = resParam.basis.insBusinessNo; //验证码需要
            this.pageForm.vehicle.jgCheckNo = resParam.vehicle.jgCheckNo; //验证码 中银需要
            this.$store.dispatch("car/setQuote", this.pageForm);
            this.$refs.checkCodeRef.open(resParam);
            return false;
          }
          this.pageForm.vehicle.jgCheckCode = null; //交管所验证码
          this.pageForm.vehicle.jgCheckNo = null; //验证码 中银需要
          this.pageForm.basis.insBusinessNo = null; //验证码需要
          this.pageForm.vehicle.insVehicleModelId = null; //车型二次确认
          this.$store.dispatch("car/setQuote", this.pageForm);
          const resData = {};
          //充电桩特殊处理
          if(reqParam.chargePileList){
            resParam.chargePileList=reqParam.chargePileList
          }else{
            resParam.chargePileList=null
          }
          resData[reqParam.basis.insuredOrgCode] = resParam;
          await this.$store.dispatch("car/setQuoteRes", resData); //保存报价结果
          this.$router.push({ path: "/miniprogram/quote/result" });
        })
        .catch((error) => {
          clearInterval(setInter)
          Toast.clear()
          Dialog.alert({
            message: error.message || "报价失败",
          }).then(() => {});
          this.pageForm.vehicle.jgCheckCode = null; //交管所验证码
          this.pageForm.vehicle.jgCheckNo = null; //验证码 中银需要
          this.pageForm.basis.insBusinessNo = null; //验证码需要
          this.pageForm.vehicle.insVehicleModelId = null; //车型二次确认
          this.$store.dispatch("car/setQuote", this.pageForm);
        });
    },
    selectVehicle(car) {
      Object.assign(this.pageForm.vehicle, car);
      this.vehicleQuote();
    },
    checkCode(val) {
      this.pageForm.vehicle.jgCheckCode = val;
      this.vehicleQuote();
    },
    async handleFileOcr(upLoadObj, reqParam) {
      let { file } = upLoadObj;
      let isLt5M = file.size / 1024 / 1024 < 5;
      const suffixMap = {
        "image/jpeg": ".jpeg",
        "image/jpg": ".jpg",
        "image/png": ".png",
      };
      if (!isLt5M) {
        if (
          !(
            suffixMap[file.type] &&
            [".jpg", ".png", ".jpeg"].includes(suffixMap[file.type])
          )
        ) {
          Toast("只能上传5M以下的图片");
          return;
        }
        if (
          suffixMap[file.type] &&
          [".jpg", ".png", ".jpeg"].includes(suffixMap[file.type])
        ) {
          // 超过5m的图片文件, 启用压缩
          const compressFiles = await new ImagesQuicklyCompress({
            mode: "pixel", // 根据像素总大小压缩
            num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
            size: "500kb", // 图片大小超过500kb执行压缩
            imageType: file.type, // jpeg压缩效果十分理想
            quality: 0.8,
            orientation: false,
          }).compressor([file]);
          isLt5M = compressFiles[0].size / 1024 / 1024 < 5;
          file = new window.File([compressFiles[0]], file.name, {
            type: compressFiles[0].type,
          });
        }
        if (!isLt5M) {
          Toast("请上传5M以下的图片");
          return;
        }
      }
      // 通过 FormData 对象上传文件
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", reqParam.type);
      // 发起请求
      request({
        url: "/afis-engine/ocr/recognition",
        method: "post",
        data: formData,
        headers: {
          "Access-Token": store.getters.info && store.getters.info.token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (reqParam.type === "vehiclelicense") {
            this.setOcrVehicle(res);
          } else if (reqParam.type === "idcard") {
            this.setOcrIdCard(res, reqParam.code);
          }
        })
        .catch((err) => {
          Toast(err.message || "上传失败");
        });
    },
    setOcrVehicle(res) {
      if (!(this.pageForm && this.pageForm.vehicle)) {
        return false;
      }
      const {
        brandModel,
        engineNo,
        frameNo,
        plateNo,
        registDate,
        owner,
        useProperty,
        issueDate
      } = res;
      this.pageForm.vehicle.brandModel = brandModel;
      this.pageForm.vehicle.engineNo = engineNo;
      this.pageForm.vehicle.frameNo = frameNo;
      this.pageForm.vehicle.plateNo = plateNo;
      this.pageForm.vehicle.plateFlag = plateNo ? false : true;
      this.pageForm.vehicle.registerDate = new Date(registDate).getTime();
      this.pageForm.vehicle.vehicleUsage = useProperty === "营运" ? "1" : "2";
      this.pageForm.vehicle.modelDesc = brandModel;
      this.pageForm.vehicle.issueCardDate = new Date(issueDate).getTime();
      if (this.pageForm && this.pageForm.owner) {
        this.pageForm.owner.name = owner;
      }
      this.$store.dispatch("car/setQuote", this.pageForm);
    },
    setOcrIdCard(res, code) {
      if (!(this.pageForm && this.pageForm[code])) {
        return false;
      }
      const form = this.pageForm[code];
      const { name, idNumber, address, birthDate, sex, nation } = res;
      form.name = name;
      form.certificateNo = idNumber;
      form.homeAddress = address;
      form.gender = sex === "男" ? "1" : "2";
      form.birthday = Date.parse(birthDate);
      if (nation) {
        form.ethnicGroup = nation;
      }
      this.$store.dispatch("car/setQuote", this.pageForm);
    },
    ocrVehicle(fileObj) {
      this.handleFileOcr(fileObj, { type: "vehiclelicense" });
      if (!this.quote.ocr) {
        this.quote.ocr = {};
      }
      this.imagesQuickly('vehicle', fileObj)
      return false;
    },
    ocrOwner(fileObj) {
      this.handleFileOcr(fileObj, { type: "idcard", code: "owner" });
      if (!this.quote.ocr) {
        this.quote.ocr = {};
      }
      this.imagesQuickly('owner', fileObj)
      return false;
    },
    ocrHolder(fileObj) {
      this.handleFileOcr(fileObj, { type: "idcard", code: "holder" });
      if (!this.quote.ocr) {
        this.quote.ocr = {};
      }
      this.imagesQuickly('holder', fileObj)
      return false;
    },
    ocrInsured(fileObj) {
      this.handleFileOcr(fileObj, { type: "idcard", code: "insured" });
      if (!this.quote.ocr) {
        this.quote.ocr = {};
      }
      this.imagesQuickly('insured', fileObj)
      return false;
    },
    cardOcrCli () {
      this.$refs.cardOcr.cardShow = true
    },
    async getDetail (subBusinessNo, fromPage) {
      let res = await request({url:'/afis-auto-web/auto/policy/query', method:'post', data:{subBusinessNo: subBusinessNo}})
        if (fromPage === 'list') {
          res.resultCode = '0'
          res.resultMsgs = [res.basis.resultMsg]
        }
        res.vehicle.plateFlag = !res.vehicle.plateNo ? true : false
        // 所在地区回显
        res.owner.areaCodes = [res.owner.provinceCode, res.owner.cityCode, res.owner.countyCode]
        res.holder.areaCodes = [res.holder.provinceCode, res.holder.cityCode, res.holder.countyCode]
        res.insured.areaCodes = [res.insured.provinceCode, res.insured.cityCode, res.insured.countyCode]
        if (res.commercial) {
            res.commercial.riskList.map(item => {
                item.checked = true
                item.riskCheck = true
            })
        }
        // 投被保联系人信息处理
        // operator
        if (res.holder && res.holder.operator) {
            let operatorData = res.holder.operator
            res.holder.operator_name = operatorData.name
            res.holder.operator_certificateType = operatorData.certificateType
            res.holder.operator_certificateNo = operatorData.certificateNo
            res.holder.operator_mobile = operatorData.mobile

        }
        if (res.holder.sameOwnerFlag === 'false'||res.holder.sameOwnerFlag === '0') {
            res.holder.sameOwnerFlag = false
        }else if (res.holder.sameOwnerFlag === 'true'||res.holder.sameOwnerFlag === '1') {
            res.holder.sameOwnerFlag = true
        }
        if (res.insured && res.insured.operator) {
            let operatorData = res.insured.operator
            res.insured.operator_name = operatorData.name
            res.insured.operator_certificateType = operatorData.certificateType
            res.insured.operator_certificateNo = operatorData.certificateNo
            res.insured.operator_mobile = operatorData.mobile
        }
        if (res.insured.sameHolderFlag === 'false'||res.insured.sameHolderFlag === '0') {
            res.insured.sameHolderFlag = false
        }else if (res.insured.sameHolderFlag === 'true'||res.insured.sameHolderFlag === '1') {
            res.insured.sameHolderFlag = true
        }
        if (res.insured.sameOwnerFlag === 'false'||res.insured.sameOwnerFlag === '0') {
            res.insured.sameOwnerFlag = false
        }else if (res.insured.sameOwnerFlag === 'true'||res.insured.sameOwnerFlag === '1') {
            res.insured.sameOwnerFlag = true
        }
        
        const { info } = this.$store.getters
        if (info.busiType === '1') {
            let orgIdVal = ''
            const dictData = await request({
                url: '/organization/org/tree',
                method: "post",
                data: {}
            })
            function getStr (orgId) {
                dictData.map(item => {
                    if (item.id === orgId) {
                        orgIdVal += '/' + orgId
                        getStr(item.parentId)
                    }
                })
                return orgIdVal
            }
            getStr(res.basis.orgId)
            orgIdVal = orgIdVal.split('/')
            orgIdVal.shift()
            orgIdVal.reverse()
            res.basis.orgIdVal = orgIdVal
        }
        this.$store.dispatch("car/setQuote", res)
        this.$set(this.quoteRes, res.basis.insuredOrgCode, res)
        this.$store.dispatch("car/setQuoteRes", this.quoteRes)
        if (fromPage === 'detail') {
          this.quote = res
          this.flag = true
        }
        if (fromPage === 'list') {
          this.$router.push({
              path: '/miniprogram/quote/result',
              query: {
                  id: subBusinessNo,
                  type: 'error'
              }
          })
      }
      // 投被保联系人信息处理
      // operator
      if (res.holder && res.holder.operator) {
          let operatorData = res.holder.operator
          res.holder.operator_name = operatorData.name
          res.holder.operator_certificateType = operatorData.certificateType
          res.holder.operator_certificateNo = operatorData.certificateNo
          res.holder.operator_mobile = operatorData.mobile

      }
      if (res.holder.sameOwnerFlag === 'false'||res.holder.sameOwnerFlag === '0') {
          res.holder.sameOwnerFlag = false
      }else if (res.holder.sameOwnerFlag === 'true'||res.holder.sameOwnerFlag === '1') {
          res.holder.sameOwnerFlag = true
      }
      if (res.insured && res.insured.operator) {
          let operatorData = res.insured.operator
          res.insured.operator_name = operatorData.name
          res.insured.operator_certificateType = operatorData.certificateType
          res.insured.operator_certificateNo = operatorData.certificateNo
          res.insured.operator_mobile = operatorData.mobile
      }
      if (res.insured.sameHolderFlag === 'false'||res.insured.sameHolderFlag === '0') {
          res.insured.sameHolderFlag = false
      }else if (res.insured.sameHolderFlag === 'true'||res.insured.sameHolderFlag === '1') {
          res.insured.sameHolderFlag = true
      }
      if (res.insured.sameOwnerFlag === 'false'||res.insured.sameOwnerFlag === '0') {
          res.insured.sameOwnerFlag = false
      }else if (res.insured.sameOwnerFlag === 'true'||res.insured.sameOwnerFlag === '1') {
          res.insured.sameOwnerFlag = true
      }
      // 点击调整方案指控特约条款
      if (res.compulsory) {
        if (res.basis.insuredStatus !== '0') {
            res.compulsory.specialTermList = []
        }
        res.compulsory.checked = true
      } else {
        res.compulsory = {}
      }
      if (res.commercial) {
        if (res.basis.insuredStatus !== '0') {
            res.commercial.specialTermList = []
        }
        res.commercial.checked = true
      } else {
        res.commercial = {}
      }
      //充电桩处理
      if(res.chargePileList&&res.chargePileList.length>0){
        res.chargePileList.forEach(item=>{
          item.areaCodes=[item.provinceCode, item.cityCode, item.countyCode]
        })
        const filterRisk=res.commercial&&res.commercial.riskList.filter((item)=>{return item.riskCode==='COMM1M02'||item.riskCode==='COMM1M03'})
        filterRisk.forEach(item=>{
          item.chargePileList=res.chargePileList
        })
      }
      this.formData = res
      if (this.info.busiType === '1') {
          let orgIdVal = ''
          const dictData = await request({
              url: '/organization/org/tree',
              method: "post",
              data: {}
          })
          function getStr (orgId) {
              dictData.map(item => {
                  if (item.id === orgId) {
                      orgIdVal += '/' + orgId
                      getStr(item.parentId)
                  }
              })
              return orgIdVal
          }
          getStr(res.basis.orgId)
          orgIdVal = orgIdVal.split('/')
          orgIdVal.shift()
          orgIdVal.reverse()
          res.basis.orgIdVal = orgIdVal
      }
      this.$store.dispatch("car/setQuote", res)
      this.$set(this.quoteRes, res.basis.insuredOrgCode, res)
      this.$store.dispatch("car/setQuoteRes", this.quoteRes)
      if (fromPage === 'detail') {
        this.quote = res
        this.flag = true
      }
      if (fromPage === 'list') {
        this.$router.push({
            path: '/miniprogram/quote/result',
            query: {
                id: subBusinessNo,
                type: 'error'
            }
        })
      }
      if (fromPage !== 'list') {
          this.initBtn(res.basis.insuredOrgCode)
      }
    },
    async imagesQuickly (key, fileObj, side) {
      // 超过5m的图片文件, 启用压缩
      let { file } = fileObj;
      const compressFiles = await new ImagesQuicklyCompress({
        mode: "pixel", // 根据像素总大小压缩
        num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
        size: "500kb", // 图片大小超过500kb执行压缩
        imageType: file.type, // jpeg压缩效果十分理想
        quality: 0.8,
        orientation: false,
      }).compressor([file]);
      file = new window.File([compressFiles[0]], file.name, {
        type: compressFiles[0].type,
      });
      var reader = new FileReader();
      reader.readAsDataURL(compressFiles[0]);
      reader.onload = function(){
        let cardImg = store.getters.cardImg
        cardImg[key] = reader.result
        store.dispatch("car/setCardImg", cardImg)
      };
    }
  },
};
